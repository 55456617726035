import React from 'react';
import Image from 'next/image';
import router from 'next/router';
import { useSelector } from 'react-redux';
import styles from './NotFound.module.scss';
import BgImageDark from '../../../public/images/404_dark.png';
import BgImageLight from '../../../public/images/404.png';
import Button from '../UI/Button/Button';

const NotFound = () => {
  const lightMode = useSelector((state) => state.main.lightMode);

  return (
    <div className={styles.notFound}>
      <Image
        width="900"
        height="288"
        src={lightMode ? BgImageLight : BgImageDark}
        alt="404 Not found"
      />
      <p>
        Sorry, the content you’re looking for doesn’t exist.
      </p>
      <Button
        size="large"
        action={() => { router.push('/feed'); }}
      >
        Go to homepage
      </Button>
    </div>
  );
};

export default NotFound;
