/* eslint-disable no-alert, no-console, linebreak-style, jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import { useRouter } from 'next/router';
import 'aos/dist/aos.css';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import { getStore } from '../../../store';
import { resetRedirectState } from '../../../common/data/actions';
import styles from './NoSessionNav.module.scss';
import Button from '../UI/Button/Button';
import firebase from '../../firebase';
import Modal from '../UI/Modal/Modal';
import NewAuth from '../Onboardings/Auth/NewAuth/NewAuth';

const NoSessionNav = () => {
  const lightMode = useSelector((state) => state.main.lightMode);
  const [showLogo, setShowLogo] = useState(lightMode ? 'logo.png' : '/logo_dark.png');
  const router = useRouter();
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    console.log(router);
    if (router.pathname === '/privacy' || router.pathname === '/terms' || router.pathname === '/cookies') {
      setShowLogo('/logo.png');
    }
    AOS.init({
      once: true,
    });
  }, []);

  const logOut = () => {
    firebase.auth().signOut();
    getStore().dispatch(resetRedirectState());
    router.push('/');
  };
  const [activeModal, setActiveModal] = useState('');
  const closeModal = () => {
    setActiveModal('');
  };

  return (
    <>
      <div>
        <div
          className={styles.nav}
          data-aos="fade-down"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <div className={styles.nav__wrapper}>
            <div className={styles.logo}>
              <Link href="/">
                <img
                  alt="entre"
                  src={showLogo}
                />
              </Link>
            </div>
            {!token ? (
              <div className={styles.nav__btn}>
                <button type="button" className={styles.authBtn} onClick={() => setActiveModal('auth')}>
                  Login / Sign Up
                </button>
              </div>
            ) : null}
            {router.pathname === '/onboarding' ? (
              <div className={styles.nav__logOutbtn}>
                <Button
                  variant="secondary"
                  action={() => logOut()}
                >
                  Logout
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        isOpen={activeModal === 'auth'}
        onClose={closeModal}
        topOffSet="20%"
        maximumWidth="28rem"
      >
        <NewAuth redirect={router.asPath} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default NoSessionNav;
