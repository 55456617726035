import { useSelector } from 'react-redux';
import NoSessionNav from '../src/components/NoSessionNav/NoSessionNav';
import ThreeColumns from '../src/hoc/Layout/ThreeColumns';

import NotFound from '../src/components/NotFound/NotFound';
import MainNavBar from '../src/components/NavBars/NavBar/MainNavBar';

export default function Custom404() {
  const token = useSelector((state) => state.auth.token);

  return (
    <>
      {token ? (
        <MainNavBar />
      ) : (
        <NoSessionNav />
      )}
      <ThreeColumns
        center={(
          <>
            <NotFound />
          </>
        )}
      />
    </>
  );
}
