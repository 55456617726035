/* eslint-disable no-alert, no-console, linebreak-style */
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import styles from './ThreeColumns.module.scss';

const ThreeColumns = ({
  left, center, right, header, headFilters, sideResponsive, isPadLess,
}) => (
  <div className={clsx(styles.layout, sideResponsive && styles.sideResponsive,
    isPadLess && styles.mobilePadding)}
  >
    {header && (
    <div className={clsx(styles.PageHeader, headFilters && styles.increHeight)}>
      {header}
    </div>
    )}
    <div className={styles.sidebars} id="sidebar">
      <div className={styles.left}>
        {left}
      </div>
      <div className={styles.right}>
        {right}
      </div>
    </div>
    <main className={styles.center}>
      {center}
    </main>
  </div>
);

ThreeColumns.defaultProps = {
  left: null,
  center: null,
  right: null,
  header: null,
  headFilters: false,
  sideResponsive: false,
  isPadLess: false,
};

ThreeColumns.propTypes = {
  left: PropTypes.element,
  center: PropTypes.element,
  right: PropTypes.element,
  header: PropTypes.element,
  headFilters: PropTypes.bool,
  sideResponsive: PropTypes.bool,
  isPadLess: PropTypes.bool,
};

export default ThreeColumns;
